



















































import {Component, Mixins, Vue} from 'vue-property-decorator'
import AddButton from '@/general/components/common/AddButton.vue'
import { UserOverview } from '@/modules/users/models/User'
import InfoCard from '@/general/components/common/InfoCard.vue'
import BaseDataTable from '@/general/components/general/BaseDataTable.vue'
import ResyncPopup from '@/general/components/common/ResyncPopup.vue'
import {ListUsers_ListUsers} from "@/generated/ListUsers";
import UserService1 from "@/modules/users/UserService1";

@Component({
  components: {
    ResyncPopup,
    InfoCard,
    AddButton,
    BaseDataTable
  }
})
export default class Overview extends Mixins(UserService1) {
  headers = [
    {
      text: this.$t('shipment.fields.createdAt'),
      value: 'created'
    },
    {
      text: 'Email',
      value: 'email'
    }
  ]
  resync = false
  show = true

  rerender() {
    this.show = false
    this.$nextTick(() => {
      this.show = true
    })
  }

  get service() {
    return this.listUsers
  }
}
